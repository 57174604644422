import React, { useState } from "react";

import { useContext } from "react";
import { myContext } from "../../../utils/provider";

import { useStaticQuery, graphql } from "gatsby";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";

import { Button } from "gatsby-theme-material-ui";
import { useShoppingCart } from "use-shopping-cart";
import { useForm } from "react-hook-form";

import useStripeProducts from "../products/useStripeProducts";
import {
  begingCheckoutEventGA,
  removeAllItemsFromCartEventGA,
} from "../../../utils/ga-events";
import { begingCheckoutEventFB } from "../../../utils/fb-events";

const CartForm = () => {
  const countries = useStaticQuery(graphql`
    query {
      euCountryList: allEuCountriesJson {
        nodes {
          country
          countryCode
        }
      }
      allCountryList: allAllCountriesJson {
        nodes {
          country
          countryCode
        }
      }
      blockedCountryList: allBlockedCountriesJson {
        nodes {
          country
          countryCode
        }
      }
    }
  `);

  const euCountryList = countries.euCountryList.nodes;
  const allCountryList = countries.allCountryList.nodes;
  const blockedCountryList = countries.blockedCountryList.nodes;

  const [open, setOpen] = React.useState(false);

  const context = useContext(myContext);

  const allStripeProducts = useStripeProducts();

  const {
    totalPrice,
    redirectToCheckout,
    clearCart,
    cartDetails,
    handleCloseCart,
    cartCount,
    currency,
  } = useShoppingCart();

  const [clickedCheckout, setClickedCheckout] = useState(false);

  let formMode = "payment";
  if (cartDetails) {
    for (const product of Object.values(cartDetails)) {
      if (product.type === "connect") {
        formMode = "subscription";
        break;
      }
    }
  }

  const {
    handleSubmit,

    formState: { isValid },
  } = useForm({
    mode: formMode,
  });

  const formatCartItems = (cartProducts) => {
    const freeShippingProduct = allStripeProducts.find(
      (item) => item.name === "Free Shipping" && item.shippingOption === "true"
    );

    const shippingCostProduct = getShippingProduct();
    const formatedCartItems = [];

    for (const id in cartProducts) {
      const item = {
        price: cartDetails[id].price_id,
        quantity: cartDetails[id].quantity,
      };

      formatedCartItems.push(item);
    }
    if (formMode === "payment") {
      if (totalPrice < 99900) {
        const item = {
          price: shippingCostProduct.price_id,
          quantity: 1,
        };
        formatedCartItems.push(item);
      } else {
        const item = {
          price: freeShippingProduct.price_id,
          quantity: 1,
        };
        formatedCartItems.push(item);
      }
    }
    return formatedCartItems;
  };

  const getAllowedCountries = () => {
    const allowedCountries = allCountryList.filter(
      (item) =>
        !blockedCountryList.find(
          (rm) =>
            rm.country === item.country && item.countryCode === rm.countryCode
        )
    );

    let allowedCountriesCodes = allowedCountries.map((x) => x.countryCode);
    return allowedCountriesCodes;
  };

  const getShippingProduct = () => {
    let euCountry = euCountryList.some((country) => {
      if (country.countryCode === context.country) {
        return true;
      } else {
        return false;
      }
    });
    const shippingCostProduct = allStripeProducts.find(
      (element) =>
        element.shippingOption === "true" &&
        element.shippingRegionEU === euCountry
    );

    return shippingCostProduct;
  };
  async function checkoutFunction() {
    const response = await fetch(
      `${process.env.NETLIFY_FUNCTIONS_URL}checkout`,

      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          product: formatCartItems(cartDetails),
          countries: getAllowedCountries(),
          typeMode: formMode,
          currency: currency,
        }),
      }
    );

    const data = await response.json();
    redirectToCheckout(data.sessionId);
  }

  const onSubmit = () => handleCheckout();

  const handleClearCart = () => {
    removeAllItemsFromCartEventGA(cartDetails, totalPrice);
    clearCart();
  };

  const handleBackDrop = () => {
    setOpen(!open);
  };

  const handleCheckout = () => {
    setClickedCheckout(true);
    handleBackDrop();
    setTimeout(() => {
      setClickedCheckout(false);
      clearCart();
      handleCloseCart();
    }, 6000);
    begingCheckoutEventGA(cartDetails, totalPrice);
    begingCheckoutEventFB(cartDetails, totalPrice);
    checkoutFunction();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid
        container
        marginTop={3}
        marginBottom={3}
        paddingLeft={2}
        paddingRight={2}
        justifyContent={{ xs: "center" }}
        spacing={2}
      >
        <Button
          variant="contained"
          style={{ color: "#fff", fontWeight: "600", marginTop: "2em" }}
          type="submit"
          fullWidth="true"
          disabled={!isValid || clickedCheckout || cartCount === 0}
          sx={{
            width: "160px",
            display: "grid",
            placeItems: "center",
            ":disabled": {
              opacity: 0.5,
              cursor: "default",
            },
            ":disabled:hover, :disabled:active, :disabled:focus": {
              bg: "primary",
            },
          }}
        >
          {clickedCheckout ? (
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={open}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          ) : (
            `Go to Checkout`
          )}
        </Button>
      </Grid>
    </form>
  );
};

export default CartForm;
